<template>
  <v-expansion-panel class="mb-2">
    <v-expansion-panel-header>
      <span><v-icon class="mr-1" small>mdi-clock</v-icon> Configurar Hora</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-card class="mb-2" flat width="900">
        <v-card-title>Hour Formats:</v-card-title>
        <v-card-text v-if="hourFormats && !Object.keys(hourFormats).length">Não há formatos de data configurados.</v-card-text>
        <v-card-text v-for="(value, key) in hourFormats" v-else :key="key" class="pb-0">
          <v-text-field v-model="hourFormats[key]" hide-details :disabled="!editable" :label="key" outlined>
            <template v-if="editable" v-slot:append-outer>
              <div>
                <v-icon class="mx-auto" color="error" @click="openChangesDialog(key)">mdi-close-box</v-icon>
              </div>
            </template>
          </v-text-field>
        </v-card-text>
        <v-row no-gutters>
          <btn-card-actions class="mr-0" :editable.sync="editable" @cancel="editable = false" @save="saveManager" />
          <v-btn v-if="editable" class="mt-4" width="260" color="primary" outlined @click="openAddFormat"><v-icon>mdi-plus</v-icon> Adicionar</v-btn>
        </v-row>
        <change-dialog v-if="changesDialogEdit" :dialog.sync="changesDialogEdit" :message.sync="changesMessage" @proceed="saveFormats" />
        <change-dialog v-if="changesDialogDelete" :dialog.sync="changesDialogDelete" :message.sync="changesMessage" @proceed="removeField" />
      </v-card>

      <!-- add format dialog -->
      <process-date-hour-dialog
        v-if="hourFormats"
        :is-deploying="isDeploying"
        :account-id="accountId"
        :dialog="addFormatDialog"
        format-flag="hour"
        :formats="Object.keys(hourFormats)"
        @close="closeAddFormat"
      />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { MUTATION_REMOVE_FORMAT, MUTATION_UPDATE_FORMATS } from '@/modules/accounts/graphql'

export default {
  components: {
    BtnCardActions: () => import('@/components/base/BtnCardActions'),
    ChangeDialog: () => import('@/components/base/ChangeDialog'),
    ProcessDateHourDialog: () => import('@/modules/accounts/partials/ProcessDateHourDialog')
  },
  props: {
    accountId: String,
    hourFormats: {
      type: Object,
      default: () => ({})
    },
    isDeploying: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editable: false,
    addFormatDialog: false,
    changesDialogEdit: false,
    changesDialogDelete: false,
    changesMessage: '',
    key: null
  }),
  methods: {
    async saveFormats() {
      this.$setLogMessage(this.changesMessage)

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_FORMATS,
          variables: {
            id: this.accountId,
            formats: this.hourFormats,
            key: 'hour'
          }
        })
        this.$snackbar({ message: 'Formatos atualizados com sucesso', snackbarColor: '#2E7D32' })
        this.editable = false
        this.$emit('cancel')
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao salvar formatos', snackbarColor: '#F44336' })
      }
    },
    async removeField() {
      this.$setLogMessage(this.changesMessage)

      try {
        await this.$apollo.mutate({
          mutation: MUTATION_REMOVE_FORMAT,
          variables: {
            id: this.accountId,
            key: this.key,
            table: 'hour'
          }
        })
        this.editableDate = false
        this.key = null
        this.$snackbar({ message: `Formato "${this.key}" removido`, snackbarColor: '#2E7D32' })
        this.$emit('cancel')
      } catch (err) {
        console.log(err.message)
        this.$snackbar({ message: 'Falha ao remover formato', snackbarColor: '#F44336' })
      }
    },
    openChangesDialog(key) {
      this.key = key
      if (this.isDeploying) return this.removeField()
      this.changesDialogDelete = true
    },
    openAddFormat() {
      this.addFormatDialog = true
    },
    closeAddFormat() {
      this.addFormatDialog = false
      this.$emit('cancel')
    },
    saveManager() {
      if (this.isDeploying) return this.saveFormats()
      this.changesDialogEdit = true
    }
  }
}
</script>
